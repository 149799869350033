/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../assets/imgs/logo.png';
import Services from '../../services/authService';

import './styles.css';

function Header() {
  const navigate = useNavigate();
  const [isAuthenticate, setAuth] = useState(false);
  const [menu, setMenu] = useState(false);
  const [corp, setCorp] = useState(false);
  const [doc, setDoc] = useState('');
  const [partner, setPartner] = useState(false);
  // const [emailUser, setEmailUser] = useState("");

  const logout = () => {
    localStorage.setItem('token', '');
    localStorage.setItem('nameUser', '');
    localStorage.setItem('emailUser', '');
    setAuth(false);
    navigate('/');
  };

  function init() {
    let token = localStorage.getItem('token');
    // setNameUser(localStorage.getItem('nameUser'));
    // setEmailUser(localStorage.getItem('emailUser'));
    if(token !== '' && token !== null)
      setAuth(true);

    let data = {
      email: localStorage.getItem('emailUser')
    };
    if(data.email !== "fenix"){
      Services.getUser(data).then(function(res){
        let doc = res.data.user.doc.replace(/[^0-9]/g,'');
        setDoc(res.data.user.doc.replace(/[^0-9]/g,''));
        Services.verifyCorp({doc: doc}).then(function(res){
          if(res.data.corp){
            setCorp(true);
          }
          }).catch(function(err){
          });

          Services.verifyPartner({doc: doc}).then(function(res){
            if(res.data.partner){
              setPartner(true);
            }
          }).catch(function(err){
          });
        }).catch(function(err){
        });
    }
  }
  const changeMenu = () => {
    setMenu(!menu);
    document.getElementById("sectionHeaderComponent").scrollTo(0,0);
  }

  React.useEffect(() => {
    init();
  },[]);

  return (
    <section className={"mainContentHeaderComponent " + (menu?"mainContentHeaderComponentBig":menu?"mainContentHeaderComponentSmall":"mainContentHeaderComponentSmallSolid")} id="sectionHeaderComponent">
      {   !isAuthenticate &&
        <div>
        {
          !menu &&
          <label className="iconMenuTrue" onClick={() => changeMenu()}>&#9776;</label>
        }
        {
          // menu &&
          // <div>
          //   <svg className="iconMenuFalse" onClick={() => changeMenu()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          //     <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="white"/>
          //   </svg>
          //   <span className="block"></span>
          // </div>
        }

          <ul className="btnList">
            <a href="/" className={"aHeaderComponent btnLogoBig " + (menu?"aHeaderComponentNone":"aHeaderComponentBlock")}>
              <li className="btn btnType1 borderNone" style={{marginLeft: '0px', paddingLeft: '0px'}}>
                <img className="logo" src={Logo} alt="higher" />
              </li>
            </a>
            {/*<a href="/who-we-are" className="aHeaderComponent">
              <li className="btn btnType1">Quem Somos</li>
            </a>
            <a href="/solutions" className="aHeaderComponent">
              <li className="btn btnType1">Soluções</li>
            </a>
            <a href="/how-works" className="aHeaderComponent">
              <li className="btn btnType1">Como Funciona</li>
            </a>
            <a href="/plans" className="aHeaderComponent">
              <li className="btn btnType1">Planos</li>
            </a>*/}
            <a href="/" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType1">Home</li>
            </a>
            <a href="/remessa-quote" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType1">Remessa</li>
            </a>
            <a href="/who-we-are" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType1">Quem Somos</li>
            </a>
            <a href="/faq" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType1">FAQ</li>
            </a>
            <a href="/register" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType2">Cadastro</li>
            </a>
            <a href="/login" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <li className="btn btnType3">Login</li>
            </a>
            {
              menu &&
              <li className="btn" style={{paddingLeft: '0px'}}>
                <svg className="iconMenuFalse" onClick={() => changeMenu()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="white"/>
                </svg>
              </li>
            }
          </ul>
        </div>
      }

      {   isAuthenticate &&
          <div>
          {
            !menu &&
            <label className="iconMenuTrue" onClick={() => changeMenu()}>&#9776;</label>
          }
          <ul className="btnList btnListSecondary">

            <a href="/user" className={"aHeaderComponent btnLogoBig " + (menu?"aHeaderComponentNone":"aHeaderComponentBlock")}>
              <li className="btn btnType1 borderNone" style={{marginLeft: '5px', paddingLeft: '0px'}}>
                <img className="logo" src={Logo} alt="higher" />
              </li>
            </a>

            {
              (corp || partner)  &&
              <a href="/user" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Home</li>
              </a>
            }
            {
              partner &&
              <a href="/partner" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Parceiro</li>
              </a>
            }
            {
              corp &&
              <a href="/remessa-corp" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Fênix</li>
              </a>
            }
            {
              corp &&
              <a href="/remessa-corp-rp" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Ruplast</li>
              </a>
            }
            {
              (corp || doc === '32162515865') &&
              <a href="/remessa-corp-bb" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Brasil Bitcoin</li>
              </a>
            }
            {
              corp &&
              <a href="/remessa-corp-rx" className={"aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
                <li className="btn btnType1">Rebel X</li>
              </a>
            }
            <div className={"contentBtnHeaderComponent aHeaderComponent " + (menu?"aHeaderComponentBlock":"aHeaderComponentNone")}>
              <div className="btn btnType3" onClick={() => logout()}>Sair</div>
            </div>
            {
              menu &&
              <li className="btn" style={{paddingLeft: '0px'}}>
                <svg className="iconMenuFalse" onClick={() => changeMenu()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="white"/>
                </svg>
              </li>
            }
          </ul>
        </div>
      }
    </section>
  );
}

export default Header;
