import React from 'react';
import Header from '../../components/HeaderOld';
import './styles.scss';

import Filter from '../../assets/imgs/filter.png';

import Remessa from '../../services/remessaService';
import Services from '../../services/authService';
import RemessaCorpFenixServices from '../../services/RemessaCorp/fenixService';
import RemessaCorpBrasilBitcoinServices from '../../services/RemessaCorp/brasilBitcoinService';
import RemessaCorpRebelXServices from '../../services/RemessaCorp/rebelXService';
import RemessaCorpRuplastServices from '../../services/RemessaCorp/ruplastService';

class Partner extends React.Component {
constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      quoteId: '',
      customerId: '',
      status: '',
      startDate: '',
      endDate: '',
      doc: '',
      partner: false,
      partnerDoc: '',
      corpDoc: '',
      transactionsFenixCorpAPI: [],
      transactionsBrasilBitcoinCorpAPI: [],
      transactionsRuplastCorpAPI: [],
      transactionsRebelXCorpAPI: [],
      load: true,
      heightFilter: 0,
    };
    this.onInputchange = this.onInputchange.bind(this);
  }

  componentDidMount() {
    let that = this;
    let data = {
      email: localStorage.getItem('emailUser')
    };
    Services.getUser(data).then(function(res){
      let doc = res.data.user.doc.replace(/[^0-9]/g,'');
      that.setState({
        doc: doc
      });
      Services.verifyPartner({doc: doc}).then(function(res){
        if(res.data.partner){
          that.setState({
            partner: true,
            load: false,
          });
          Remessa.authToken().then(function(){
            RemessaCorpFenixServices.authCorp().then(function(){
              RemessaCorpBrasilBitcoinServices.authCorp().then(function(){
                RemessaCorpRebelXServices.authCorp().then(function(){
                  RemessaCorpRuplastServices.authCorp().then(function(){
                    that.getTransactions();
                  });
                });
              });
            });
          });
        }
      }).catch(function(err){
        that.setState({
          load: false
        });
      });
    }).catch(function(err){
      that.setState({
        load: false
      });
      console.error("Falha na requisição");
    });
  }

  getTransactions(){
    const that = this;
    Remessa.auth().then(function(res){
      if(res.data.token !== null){
        let searchUrl = '';
        if(that.state.quoteId !== '')
          searchUrl = searchUrl + 'quoteId=' + that.state.quoteId + '&';
        if(that.state.customerId !== '')
          searchUrl = searchUrl + 'customerId=' + that.state.customerId + '&';
        if(that.state.status !== '')
          searchUrl = searchUrl + 'status=' + that.state.status + '&';
        if(that.state.startDate !== '')
          searchUrl = searchUrl + 'startDate=' + that.state.startDate + '&';
        if(that.state.endDate !== ''){
          let date = new Date(that.state.endDate);
          date.setDate(date.getDate() + 2);
          searchUrl = searchUrl + 'endDate=' + date.toISOString();
        }

        Remessa.getAllTransactions({
          token: res.data.token,
          searchUrl: searchUrl
        }).then(function(resTransactions){
          that.setState({transactions: resTransactions.data.res, heightFilter: 0});
          for(let i=0; i<resTransactions.data.res.length; i++){
            that.getRecipient(resTransactions.data.res[i].counterpartyId);
          }
        });
      }
    });

    RemessaCorpFenixServices.auth().then(function(res){
    }).catch(function(err){
    });
    RemessaCorpFenixServices.returnTransactions({
      "initDate": that.state.startDate?that.formatNewDate(new Date(that.state.startDate).toString()):"2022-10-01",
      "endDate": that.state.endDate?that.formatNewDate(new Date(that.state.endDate).toString()):that.formatNewDate(new Date().toString()),
      "token": localStorage.getItem('tokenRemessaCorp'),
    }).then(function(res){
      let transactionsFenixCorpAPI = [];
      for(let i=0; i< res.data.res.transactions.length; i++){
        transactionsFenixCorpAPI.push({
          index: i,
          direction: res.data.res.transactions[i].direction,
          id: res.data.res.transactions[i].id,
          spot: (Number(res.data.res.transactions[i].exchangeRate.toFixed(4)) + 0.09).toFixed(4),
          spread: res.data.res.transactions[i].spread,
          created: res.data.res.transactions[i].createdAt,
          exchangeRate: res.data.res.transactions[i].exchangeRate.toFixed(4),
          valorUSD: res.data.res.transactions[i].baseCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalQuotedAmount*100)),
          valor: res.data.res.transactions[i].quotedCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalBaseAmount*100)),
          vet:  res.data.res.transactions[i].vet.toFixed(2),
          valorNumericoBRL:  res.data.res.transactions[i].totalBaseAmount,
          valorNumericoUSD:  res.data.res.transactions[i].totalQuotedAmount,
          dispMoreInfo: false,
        });
      }
      that.setState({transactionsFenixCorpAPI: transactionsFenixCorpAPI});
    }).catch(function(err){
    });

    RemessaCorpBrasilBitcoinServices.auth().then(function(res){
    }).catch(function(err){
    });
    RemessaCorpBrasilBitcoinServices.returnTransactions({
      "initDate": that.state.startDate?that.formatNewDate(new Date(that.state.startDate).toString()):"2022-10-01",
      "endDate": that.state.endDate?that.formatNewDate(new Date(that.state.endDate).toString()):that.formatNewDate(new Date().toString()),
      "token": localStorage.getItem('tokenRemessaCorpBrasilBitcoin'),
    }).then(function(res){
      let transactionsBrasilBitcoinCorpAPI = [];
      for(let i=0; i< res.data.res.transactions.length; i++){
        transactionsBrasilBitcoinCorpAPI.push({
          index: i,
          direction: res.data.res.transactions[i].direction,
          id: res.data.res.transactions[i].id,
          spot: (Number(res.data.res.transactions[i].exchangeRate.toFixed(4)) + 0.09).toFixed(4),
          spread: res.data.res.transactions[i].spread,
          created: res.data.res.transactions[i].createdAt,
          exchangeRate: res.data.res.transactions[i].exchangeRate.toFixed(4),
          valorUSD: res.data.res.transactions[i].baseCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalQuotedAmount*100)),
          valor: res.data.res.transactions[i].quotedCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalBaseAmount*100)),
          vet:  res.data.res.transactions[i].vet.toFixed(2),
          valorNumericoBRL:  res.data.res.transactions[i].totalBaseAmount,
          valorNumericoUSD:  res.data.res.transactions[i].totalQuotedAmount,
          dispMoreInfo: false,
        });
      }
      that.setState({transactionsBrasilBitcoinCorpAPI: transactionsBrasilBitcoinCorpAPI});
    }).catch(function(err){
    });

    RemessaCorpRuplastServices.auth().then(function(res){
    }).catch(function(err){
    });
    RemessaCorpRuplastServices.returnTransactions({
      "initDate": that.state.startDate?that.formatNewDate(new Date(that.state.startDate).toString()):"2022-10-01",
      "endDate": that.state.endDate?that.formatNewDate(new Date(that.state.endDate).toString()):that.formatNewDate(new Date().toString()),
      "token": localStorage.getItem('tokenRemessaCorpRuplast'),
    }).then(function(res){
      let transactionsRuplastCorpAPI = [];
      for(let i=0; i< res.data.res.transactions.length; i++){
        transactionsRuplastCorpAPI.push({
          index: i,
          direction: res.data.res.transactions[i].direction,
          id: res.data.res.transactions[i].id,
          spot: (Number(res.data.res.transactions[i].exchangeRate.toFixed(4)) + 0.09).toFixed(4),
          spread: res.data.res.transactions[i].spread,
          created: res.data.res.transactions[i].createdAt,
          exchangeRate: res.data.res.transactions[i].exchangeRate.toFixed(4),
          valorUSD: res.data.res.transactions[i].baseCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalQuotedAmount*100)),
          valor: res.data.res.transactions[i].quotedCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalBaseAmount*100)),
          vet:  res.data.res.transactions[i].vet.toFixed(2),
          valorNumericoBRL:  res.data.res.transactions[i].totalBaseAmount,
          valorNumericoUSD:  res.data.res.transactions[i].totalQuotedAmount,
          dispMoreInfo: false,
        });
      }
      that.setState({transactionsRuplastCorpAPI: transactionsRuplastCorpAPI});
    }).catch(function(err){
    });

    RemessaCorpRebelXServices.auth().then(function(res){
    }).catch(function(err){
    });
    RemessaCorpRebelXServices.returnTransactions({
      "initDate": that.state.startDate?that.formatNewDate(new Date(that.state.startDate).toString()):"2022-10-01",
      "endDate": that.state.endDate?that.formatNewDate(new Date(that.state.endDate).toString()):that.formatNewDate(new Date().toString()),
      "token": localStorage.getItem('tokenRemessaCorpRebelX'),
    }).then(function(res){
      let transactionsRebelXCorpAPI = [];
      for(let i=0; i< res.data.res.transactions.length; i++){
        transactionsRebelXCorpAPI.push({
          index: i,
          direction: res.data.res.transactions[i].direction,
          id: res.data.res.transactions[i].id,
          spot: (Number(res.data.res.transactions[i].exchangeRate.toFixed(4)) + 0.09).toFixed(4),
          spread: res.data.res.transactions[i].spread,
          created: res.data.res.transactions[i].createdAt,
          exchangeRate: res.data.res.transactions[i].exchangeRate.toFixed(4),
          valorUSD: res.data.res.transactions[i].baseCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalQuotedAmount*100)),
          valor: res.data.res.transactions[i].quotedCurrencyIso + " " + that.formatMoneyValues(parseInt(res.data.res.transactions[i].totalBaseAmount*100)),
          vet:  res.data.res.transactions[i].vet.toFixed(2),
          valorNumericoBRL:  res.data.res.transactions[i].totalBaseAmount,
          valorNumericoUSD:  res.data.res.transactions[i].totalQuotedAmount,
          dispMoreInfo: false,
        });
      }
      that.setState({transactionsRebelXCorpAPI: transactionsRebelXCorpAPI});
    }).catch(function(err){
    });
  }

  formatNewDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  formatMoneyValues(int) {
    var tmp = int+'';
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if( tmp.length > 6 )
      tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    if(tmp.length>10){
      let tmpreplace = '';
      let refer;
      for(let i=tmp.length-1; i>=0; i--){
        tmpreplace = tmp[i] + tmpreplace;
        if(tmp[i] === ".")
          refer = i;
        if((refer-3 === i || refer-6 === i || refer-9 === i || refer-12 === i || refer-15 === i) && i !== 0)
          tmpreplace = "." + tmpreplace;
      }
      tmp = tmpreplace;
    }
    if(tmp === "NaN")
      return "-"
    return tmp;
  }

  getRecipient(recipient_id){
    const that = this;
    Remessa.getRecipientById({recipient_id: recipient_id}).then(function(res){
      if(res.data.recipient !== null){
        let transactions = that.state.transactions;
        for(let i=0; i<transactions.length; i++){
            if(transactions[i].counterpartyId === res.data.recipient.recipient_id){
              transactions[i].coin = res.data.recipient.currency;
              transactions[i].recipient_name = res.data.recipient.recipient_name;
              transactions[i].account = res.data.recipient.account;
              transactions[i].country = res.data.recipient.country;
              transactions[i].purpose = res.data.recipient.purpose;
              transactions[i].swift_code = res.data.recipient.swift_code;
              transactions[i].address = res.data.recipient.address;
              transactions[i].zip_code = res.data.recipient.zip_code;
              transactions[i].dispMoreInfo = false;
            }
        }
        that.setState({transactions: transactions});
      }
    });
  }

  onInputchange(event) {
    if(event.target.name === 'startDate' || event.target.name === 'endDate'){
      this.setState({
        [event.target.name]: new Date(event.target.value).toISOString()
      });
    }
    else{
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  }

  formatDate(date){
    return date.substring(8,10) + "/" + date.substring(5,7) + "/" + date.substring(0,4) + " - " + date.substring(11,19);
  }

  clearFilter() {
    this.setState({
      quoteId: '',
      customerId: '',
      status: '',
      startDate: '',
      endDate: '',
      heightFilter: 0
    }, () => this.getTransactions());
  }

  getItem(transaction) {
      return (
        <div className="contentLine" key={transaction.id}>
          <div className="contentLineTdSmall">
          {
            transaction.coin !== undefined &&
            <img src={require('../../assets/imgs/'+transaction.coin+'.png')} alt="Moeda" className="coinCardHistoricUserPage"/>
          }
          {
            transaction.coin === undefined &&
            <p>
              Sem dados
            </p>
          }
          </div>
          <div className="contentLineTdSmall">
          {
            transaction.direction === 'OUTBOUND' &&
            <div className="pDataCardPartnerPage">
              Enviado
            </div>
          }
          {
            transaction.direction === 'INBOUND' &&
            <div className="pDataCardPartnerPage">
              Recebido
            </div>
          }
          </div>
          <div className="contentLineTdSmall">
            { this.getTransactionStatus(transaction.status) }
          </div>

          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.recipient_name?transaction.recipient_name:"Sem dados") }</p>
          </div>
          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.account?transaction.account:"Sem dados") }</p>
          </div>
          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.country?transaction.country:"Sem dados") }</p>
          </div>
          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.purpose?this.getPurpose(transaction.purpose):"Sem dados") }</p>
          </div>
          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.address?transaction.address:"Sem dados") }</p>
          </div>
          <div className="contentLineTdSmall">
            <p className="pDataCardPartnerPage">{ (transaction.zip_code?transaction.zip_code:"Sem dados") }</p>
          </div>
        </div>
      )
  }

  getPurpose(purpose) {
    switch(purpose){
      case 'OWN_ACCOUNT_ABROAD':
        return 'Conta própria no exterior';
      case 'INVESTMENT_IN_STOCKS_AND_OR_FUNDS':
        return 'Investimento em ações ou fundos';
      case 'FAMILY_MAINTENANCE':
        return 'Manutenção familiar';
      case 'SERVICE':
        return 'Serviços';
      case 'FOREIGN_AVAILABILITY':
        return 'Disponibilidade no exterior';
      case 'RESIDENT_MAINTENANCE':
        return 'Manutenção de residente';
      case 'INVESTMENT_RETURN':
        return 'Retorno de investimento';
      default:
        return '-';
    }
  }

  getTransactionStatus(status) {
    switch(status){
      case 'TRANSACTION_SETTLED':
        return 'Transação Liquidada';
      case 'PENDING':
        return 'Pendente';
      case 'AWAITING_PAYMENT':
        return 'Aguardando pagamento';
      case 'PAYMENT_UNDER_CONFIRMATION':
        return 'Pagamento em confirmação';
      case 'PAYMENT_CONFIRMED':
        return 'Pagamento confirmado';
      case 'UNDER_ANALYSIS':
        return 'Em análise';
      case 'PAYMENT_RETURNED':
        return 'Pagamento devolvido';
      case 'AWAITING_SIGNATURE':
        return 'Aguardando assinatura';
      case 'FINISHED':
        return 'Finalizado';
      case 'REFUNDED':
        return 'Devolvido';
      case 'CANCELED':
        return 'Cancelado';
      case 'TRANSACTION_CONFIRMED':
        return 'Transação confirmada';
      case 'WITHDRAW_IN_PROGRESS':
        return 'Retirada em andamento';
      case 'AWAITING_DOCUMENT_UPLOAD':
        return 'Aguardando upload do documento';
      default:
        return '-';
    }
  }

  updateHeightFilter(){
    if(this.state.heightFilter<10){
      this.setState({heightFilter: 180});
    }
    else{
      this.setState({heightFilter: 0});
    }
  }

  render() {
    return (
      <section className="mainContent">
        { this.state.partner &&
          <div>
            <Header/>

            <h2 className="h2PartnerPage">
              Histórico FX
              <img className="imgFilterPartnerPage" src={Filter} alt="Filtro" onClick={() => {this.updateHeightFilter()}} />
            </h2>
            <div className="contentFiltersPartnerPage" style={{textAlign:"left"}}>
              <div style={{height: this.state.heightFilter + "px", overflow: "hidden", textAlign:"center"}}>
                <label className="labelPartnerPage">
                  <input
                    className="inputPartnerPage"
                    name="quoteId"
                    type="text"
                    placeholder="ID da cotação"
                    onChange={this.onInputchange}
                  />
                </label>
                <label className="labelPartnerPage">
                  <input
                    className="inputPartnerPage"
                    name="customerId"
                    type="text"
                    placeholder="ID do beneficiário"
                    onChange={this.onInputchange}
                  />
                </label>
                <label className="labelPartnerPage">
                  <select
                    className="inputPartnerPageSelect"
                    name="status"
                    onChange={this.onInputchange}
                  >
                    <option value="">Status</option>
                    <option value="">Todos</option>
                    <option value="PENDING">Pendente</option>
                    <option value="AWAITING_PAYMENT">Aguardando pagamento</option>
                    <option value="PAYMENT_UNDER_CONFIRMATION">Pagamento em confirmação</option>
                    <option value="PAYMENT_CONFIRMED">Pagamento confirmado</option>
                    <option value="UNDER_ANALYSIS">Em análise</option>
                    <option value="PAYMENT_RETURNED">Pagamento devolvido</option>
                    <option value="AWAITING_SIGNATURE">Aguardando assinatura</option>
                    <option value="FINISHED">Finalizado</option>
                    <option value="REFUNDED">Devolvido</option>
                    <option value="CANCELED">Cancelado</option>
                    <option value="TRANSACTION_CONFIRMED">Transação confirmada</option>
                    <option value="WITHDRAW_IN_PROGRESS">Retirada em andamento</option>
                    <option value="AWAITING_DOCUMENT_UPLOAD">Aguardando upload do documento</option>
                    <option value="TRANSACTION_SETTLED">Transação liquidada</option>
                  </select>
                </label><br/>
                <label className="labelPartnerPage">
                  Data inicial:
                  <input
                    className="inputPartnerPage"
                    name="startDate"
                    type="date"
                    onChange={this.onInputchange}
                  />
                </label>
                <label className="labelPartnerPage">
                  Data final:
                  <input
                    className="inputPartnerPage"
                    name="endDate"
                    type="date"
                    onChange={this.onInputchange}
                  />
                </label>

                <div className="contentButtonsFilter">
                  <div className="btnFilterPartnerPage" onClick={() => {this.getTransactions();}}>
                    Filtrar
                  </div>
                  <div className="btnClearPartnerPage" onClick={() => {this.clearFilter();}}>
                    Limpar
                  </div>
                </div>
              </div>
            </div>

            <div className="contentAllPartnerPage">
            <div className="scrollPartnerPage">
              <table className="tablePartnerPageBig">
                <thead>
                  <tr>
                    <td>Moeda</td>
                  </tr>
                  <tr>
                    <td>Direção</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                  </tr>
                  <tr>
                    <td>Remetente</td>
                  </tr>
                  <tr>
                    <td>Banco</td>
                  </tr>
                  <tr>
                    <td>País</td>
                  </tr>
                  <tr>
                    <td>Motivo</td>
                  </tr>
                  <tr>
                    <td>Endereço</td>
                  </tr>
                  <tr>
                    <td>Código postal</td>
                  </tr>
                </thead>
              </table>
              <div>
                { this.state.transactions.map( (transaction) => {
                    return this.getItem(transaction);
                  }
                )}
              </div>
              </div>
            </div>

            <h2 className="h2PartnerPage">Histórico API Corp - Fênix</h2><br/>
            <div className="contentAllPartnerPage">
            <div className="scrollPartnerPage">
              <table className="tablePartnerPage">
                <thead>
                  <tr>
                    <td>Moeda</td>
                  </tr>
                  <tr>
                    <td>Direção</td>
                  </tr>
                  <tr>
                    <td>Valor USD</td>
                  </tr>
                  <tr>
                    <td>Valor BRL</td>
                  </tr>
                  <tr>
                    <td>ExchangeRate</td>
                  </tr>
                  <tr>
                    <td>VET</td>
                  </tr>
                  <tr>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Spot</td>
                  </tr>
                </thead>
              </table>
                <div>
                { this.state.transactionsFenixCorpAPI.map( (transaction) =>
                    <div className="contentLine" key={transaction.id}>
                      <div className="contentLineTd">
                        <img src={require('../../assets/imgs/USD.png').default} alt="Moeda" className="coinCardHistoricUserPage"/>
                      </div>
                      <div className="contentLineTd">
                        Recebimento
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ transaction.valor }</p>
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ transaction.valorUSD }</p>
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ transaction.exchangeRate }</p>
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ transaction.vet }</p>
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ this.formatDate(transaction.created) }</p>
                      </div>
                      <div className="contentLineTd">
                        <p className="pDataCardPartnerPage">{ transaction.spot }</p>
                      </div>
                    </div>
                )}
                </div>
                </div>
            </div>

            <h2 className="h2PartnerPage">Histórico API Corp - Brasil Bitcoin</h2><br/>
            <div className="contentAllPartnerPage">
            <div className="scrollPartnerPage">
              <table className="tablePartnerPage">
                <thead>
                  <tr>
                    <td>Moeda</td>
                  </tr>
                  <tr>
                    <td>Direção</td>
                  </tr>
                  <tr>
                    <td>Valor USD</td>
                  </tr>
                  <tr>
                    <td>Valor BRL</td>
                  </tr>
                  <tr>
                    <td>ExchangeRate</td>
                  </tr>
                  <tr>
                    <td>VET</td>
                  </tr>
                  <tr>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Spot</td>
                  </tr>
                </thead>
              </table>
              <div>
              { this.state.transactionsBrasilBitcoinCorpAPI.map( (transaction) =>
                  <div className="contentLine" key={transaction.id}>
                    <div className="contentLineTd">
                      <img src={require('../../assets/imgs/USD.png').default} alt="Moeda" className="coinCardHistoricUserPage"/>
                    </div>
                    <div className="contentLineTd">
                      Envio
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valor }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valorUSD }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.exchangeRate }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.vet }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ this.formatDate(transaction.created) }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.spot }</p>
                    </div>
                  </div>
              )}
              </div>
            </div>
            </div>

            <h2 className="h2PartnerPage">Histórico API Corp - Ruplast</h2><br/>
            <div className="contentAllPartnerPage">
            <div className="scrollPartnerPage">
              <table className="tablePartnerPage">
                <thead>
                  <tr>
                    <td>Moeda</td>
                  </tr>
                  <tr>
                    <td>Direção</td>
                  </tr>
                  <tr>
                    <td>Valor USD</td>
                  </tr>
                  <tr>
                    <td>Valor BRL</td>
                  </tr>
                  <tr>
                    <td>ExchangeRate</td>
                  </tr>
                  <tr>
                    <td>VET</td>
                  </tr>
                  <tr>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Spot</td>
                  </tr>
                </thead>
              </table>
              <div>
              { this.state.transactionsRuplastCorpAPI.map( (transaction) =>
                  <div className="contentLine" key={transaction.id}>
                    <div className="contentLineTd">
                      <img src={require('../../assets/imgs/USD.png').default} alt="Moeda" className="coinCardHistoricUserPage"/>
                    </div>
                    <div className="contentLineTd">
                      Envio
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valor }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valorUSD }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.exchangeRate }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.vet }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ this.formatDate(transaction.created) }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.spot }</p>
                    </div>
                  </div>
              )}
              </div>
            </div>
            </div>

            <h2 className="h2PartnerPage">Histórico API Corp - Rebel X</h2><br/>
            <div className="contentAllPartnerPage">
            <div className="scrollPartnerPage">
              <table className="tablePartnerPage">
                <thead>
                  <tr>
                    <td>Moeda</td>
                  </tr>
                  <tr>
                    <td>Direção</td>
                  </tr>
                  <tr>
                    <td>Valor USD</td>
                  </tr>
                  <tr>
                    <td>Valor BRL</td>
                  </tr>
                  <tr>
                    <td>ExchangeRate</td>
                  </tr>
                  <tr>
                    <td>VET</td>
                  </tr>
                  <tr>
                    <td>Data</td>
                  </tr>
                  <tr>
                    <td>Spot</td>
                  </tr>
                </thead>
              </table>
              <div>
              { this.state.transactionsRebelXCorpAPI.map( (transaction) =>
                  <div className="contentLine" key={transaction.id}>
                    <div className="contentLineTd">
                      <img src={require('../../assets/imgs/USD.png').default} alt="Moeda" className="coinCardHistoricUserPage"/>
                    </div>
                    <div className="contentLineTd">
                      Envio
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valor }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.valorUSD }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.exchangeRate }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.vet }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ this.formatDate(transaction.created) }</p>
                    </div>
                    <div className="contentLineTd">
                      <p className="pDataCardPartnerPage">{ transaction.spot }</p>
                    </div>
                  </div>
              )}
              </div>
            </div>
            </div>
          </div>
        }
        { !this.state.partner &&
          !this.state.load &&
          <div className="contentAllPartnerPage">
            <Header/>
            <div className="pNotFoundPartnerPage">
              Usuário não cadastrado como parceiro
              <a href="/user" className="aPartnerPage">
                <div className="btnHomePartnerPage">
                  Home
                </div>
              </a>
            </div>
          </div>
        }
      </section>
    )
  }
}

export default Partner;
