import SetupBackEnd from './config/config.js';

function diffHours(d1, d2) {
    d1 = new Date(d1).getTime();
    d2 = d2 || new Date().getTime();
    var df = Math.abs(d1 - d2);
    var td = {
        d: Math.round(df / (24 * 60 * 60 * 1000)),
        h: Math.round(df / (60 * 60 * 1000)),
    };

    if(td.d > 0)
        return true;
    else if(td.h > 0)
        return true;
    return false;
}

function authToken() {
    var data = SetupBackEnd.get('/remessa-auth');

    data.then(function(res){
        localStorage.setItem('dateTokenRemessa', new Date());
        localStorage.setItem('tokenRemessa', res.data.token);
    });
    return data;
}

function auth() {
    var diff  = diffHours(new Date(), new Date(localStorage.getItem('dateTokenRemessa')));


    if(diff && localStorage.getItem('tokenRemessa') !== '' && localStorage.getItem('tokenRemessa') !== null && localStorage.getItem('tokenRemessa') !== undefined){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({data: {token: localStorage.getItem('tokenRemessa')}});
            }, 1);
        });
    }

    var data = SetupBackEnd.get('/remessa-auth');

    data.then(function(res){
        localStorage.setItem('dateTokenRemessa', new Date());
        localStorage.setItem('tokenRemessa', res.data.token);
    });
    return data;
}

function createCustomer(data) {
    return SetupBackEnd.post('/remessa-create-customer', data);
}

function createQuote(data) {
    var req = SetupBackEnd.post('/remessa-quote', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-auth').then(function(res){
            localStorage.setItem('dateTokenRemessa', new Date());
            localStorage.setItem('tokenRemessa', res.data.token);
            return SetupBackEnd.post('/remessa-quote', data);
        });
    });
}

function getCustomerId(data) {
    var req = SetupBackEnd.post('/remessa-return-all-customer', data);
    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-auth').then(function(res){
            localStorage.setItem('dateTokenRemessa', new Date());
            localStorage.setItem('tokenRemessa', res.data.token);
            data.token = res.data.token;
            return SetupBackEnd.post('/remessa-return-all-customer', data);
        });
    });
}

function getAllPaymentOrder(data) {
    var req = SetupBackEnd.post('/remessa-return-all-payment-order', data);
    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-auth').then(function(res){
            localStorage.setItem('dateTokenRemessa', new Date());
            localStorage.setItem('tokenRemessa', res.data.token);
            data.token = res.data.token;
            return SetupBackEnd.post('/remessa-return-all-payment-order', data);
        });
    });
}

function sendDoc(data) {
    return SetupBackEnd.post('/remessa-upload-customer-id-doc', data);
}

function createBankAccount(data) {
    return SetupBackEnd.post('/remessa-create-bank-account', data);
}

function getBankAccounts(data) {
    return SetupBackEnd.post('/remessa-return-bank-account', data);
}

function createRecipient(data) {
    return SetupBackEnd.post('/remessa-create-recipient', data);
}

function createTransaction(data) {
    return SetupBackEnd.post('/remessa-create-transaction', data);
}

function getRecipients(data){
    return SetupBackEnd.post('/remessa-return-recipient', data);
}

function getRecipientRequirements(data){
    return SetupBackEnd.post('/remessa-return-recipient-requirements', data);
}

function getAllTransactions(data){
    return SetupBackEnd.post('/remessa-return-transactions', data);
}

function getRecipientById(data){
    return SetupBackEnd.post('/remessa-return-recipient-by-id', data);
}

function uploadDoc(data){
    return SetupBackEnd.post('/remessa-upload-doc', data);
}

function uploadDocBusiness(data){
    return SetupBackEnd.post('/remessa-upload-doc-business', data);
}

function uploadInvoice(data){
    return SetupBackEnd.post('/remessa-upload-invoice', data);
}

function getLimitById(data){
    return SetupBackEnd.post('/remessa-return-limit-by-id', data);
}

function deleteRecipient(data){
    return SetupBackEnd.post('/remessa-delete-recipient', data);
}

function shareableInboundTransaction(data){
    return SetupBackEnd.post('/shareable-inbound-transaction', data);
}

function uploadLocalImage(data){
    return SetupBackEnd.post('/upload-local-image', data);
}

function uploadLocalImageBusiness(data){
    return SetupBackEnd.post('/upload-local-image-business', data);
}

function getTransactionById(data){
    return SetupBackEnd.post('/remessa-return-transaction-by-id', data);
}

const Remessa = {
    authToken,
    auth,
    createCustomer,
    createQuote,
    getCustomerId,
    sendDoc,
    createBankAccount,
    createRecipient,
    createTransaction,
    getRecipients,
    getRecipientRequirements,
    getAllTransactions,
    getRecipientById,
    uploadDoc,
    uploadInvoice,
    getLimitById,
    deleteRecipient,
    getAllPaymentOrder,
    getBankAccounts,
    shareableInboundTransaction,
    uploadLocalImage,
    uploadDocBusiness,
    uploadLocalImageBusiness,
    getTransactionById,
}

export default Remessa;
