import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './protectedRoutes';

import Partner from '../pages/Partner';

// import Fenix from '../pages/RemessaCorp/Fenix';
// import Ruplast from '../pages/RemessaCorp/Ruplast';
// import BrasilBitcoin from '../pages/RemessaCorp/BrasilBitcoin';
// import RebelX from '../pages/RemessaCorp/RebelX';

// const User = lazy(() => import('../pages/UserOld'));
const User = lazy(() => import('../pages/User'));
const Login = lazy(() => import('../pages/Login'));
const Register = lazy(() => import('../pages/Register'));
const RecoveryPassword = lazy(() => import('../pages/RecoveryPassword'));
const BankData = lazy(() => import('../pages/BankData'));
const Beneficiaries = lazy(() => import('../pages/Beneficiaries'));
const HigherWallet = lazy(() => import('../pages/HigherWallet'));
const ReceiveShipment = lazy(() => import('../pages/ReceiveShipment'));
const RegisterData = lazy(() => import('../pages/RegisterData'));
const RemittancesAndDeposits = lazy(() => import('../pages/RemittancesAndDeposits'));
const SendShipment = lazy(() => import('../pages/SendShipment'));
const UsersAndPermissions = lazy(() => import('../pages/UsersAndPermissions'));
const HelpAndSupport = lazy(() => import('../pages/HelpAndSupport'));
const BusinessProfile = lazy(() => import('../pages/BusinessProfile'));
const Historic = lazy(() => import('../pages/Historic'));
const Clients = lazy(() => import('../pages/Clients'));

const CorpApiBrasilBitcoinHome = lazy(() => import('../pages/RemessaCorp/BrasilBitcoin/Home'));
const CorpApiBrasilBitcoinOperations = lazy(() => import('../pages/RemessaCorp/BrasilBitcoin/Operations'));
const CorpApiBrasilBitcoinClients = lazy(() => import('../pages/RemessaCorp/BrasilBitcoin/Clients'));
const CorpApiBrasilBitcoinRemessa = lazy(() => import('../pages/RemessaCorp/BrasilBitcoin/Remessa'));

const CorpApiFenixHome = lazy(() => import('../pages/RemessaCorp/Fenix/Home'));
const CorpApiFenixOperations = lazy(() => import('../pages/RemessaCorp/Fenix/Operations'));
const CorpApiFenixClients = lazy(() => import('../pages/RemessaCorp/Fenix/Clients'));
const CorpApiFenixRemessa = lazy(() => import('../pages/RemessaCorp/Fenix/Remessa'));

const CorpApiRebelXHome = lazy(() => import('../pages/RemessaCorp/RebelX/Home'));
const CorpApiRebelXOperations = lazy(() => import('../pages/RemessaCorp/RebelX/Operations'));
const CorpApiRebelXClients = lazy(() => import('../pages/RemessaCorp/RebelX/Clients'));
const CorpApiRebelXRemessa = lazy(() => import('../pages/RemessaCorp/RebelX/Remessa'));

const CorpApiRuplastHome = lazy(() => import('../pages/RemessaCorp/Ruplast/Home'));
const CorpApiRuplastOperations = lazy(() => import('../pages/RemessaCorp/Ruplast/Operations'));
const CorpApiRuplastClients = lazy(() => import('../pages/RemessaCorp/Ruplast/Clients'));
const CorpApiRuplastRemessa = lazy(() => import('../pages/RemessaCorp/Ruplast/Remessa'));

const CorpApiSandboxHome = lazy(() => import('../pages/RemessaCorp/Sandbox/Home'));
const CorpApiSandboxOperations = lazy(() => import('../pages/RemessaCorp/Sandbox/Operations'));
const CorpApiSandboxClients = lazy(() => import('../pages/RemessaCorp/Sandbox/Clients'));
const CorpApiSandboxRemessa = lazy(() => import('../pages/RemessaCorp/Sandbox/Remessa'));

class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
       <Suspense fallback={ "" }>
         <Routes>
          <Route exact path="/" element={<Login/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/register/:email/:name/:phone" element={<Register/>} />
          <Route exact path="/register/:token" element={<Register/>} />
          <Route exact path="/recovery-password/:token" element={<RecoveryPassword/>}/>
          <Route exact path="/recovery-password" element={<RecoveryPassword/>}/>

          <Route exact path='/clients' element={<PrivateRoute/>}>
            <Route exact path='/clients' element={<Clients/>}/>
          </Route>
          <Route exact path='/user' element={<PrivateRoute/>}>
            <Route exact path='/user' element={<User/>}/>
          </Route>
          <Route exact path='/historic' element={<PrivateRoute/>}>
            <Route exact path='/historic' element={<Historic/>}/>
          </Route>
          <Route exact path='/partner' element={<PrivateRoute/>}>
            <Route exact path='/partner' element={<Partner/>}/>
          </Route>
          <Route exact path='/businessProfile' element={<PrivateRoute/>}>
            <Route exact path='/businessProfile' element={<BusinessProfile/>}/>
          </Route>
          <Route exact path='/bankData' element={<PrivateRoute/>}>
            <Route exact path='/bankData' element={<BankData/>}/>
          </Route>
          <Route exact path='/beneficiaries' element={<PrivateRoute/>}>
            <Route exact path='/beneficiaries' element={<Beneficiaries/>}/>
          </Route>
          <Route exact path='/higherWallet' element={<PrivateRoute/>}>
            <Route exact path='/higherWallet' element={<HigherWallet/>}/>
          </Route>
          <Route exact path='/receiveShipment' element={<PrivateRoute/>}>
            <Route exact path='/receiveShipment' element={<ReceiveShipment/>}/>
          </Route>
          <Route exact path='/receiveShipment/:remessaId' element={<PrivateRoute/>}>
            <Route exact path='/receiveShipment/:remessaId' element={<ReceiveShipment/>}/>
          </Route>
          <Route exact path='/registerData' element={<PrivateRoute/>}>
            <Route exact path='/registerData' element={<RegisterData/>}/>
          </Route>
          <Route exact path='/remittancesAndDeposits' element={<PrivateRoute/>}>
            <Route exact path='/remittancesAndDeposits' element={<RemittancesAndDeposits/>}/>
          </Route>
          <Route exact path='/sendShipment' element={<PrivateRoute/>}>
            <Route exact path='/sendShipment' element={<SendShipment/>}/>
          </Route>
          <Route exact path='/sendShipment/:remessaId' element={<PrivateRoute/>}>
            <Route exact path='/sendShipment/:remessaId' element={<SendShipment/>}/>
          </Route>
          <Route exact path='/usersAndPermissions' element={<PrivateRoute/>}>
            <Route exact path='/usersAndPermissions' element={<UsersAndPermissions/>}/>
          </Route>
          <Route exact path='/helpAndSupport' element={<PrivateRoute/>}>
            <Route exact path='/helpAndSupport' element={<HelpAndSupport/>}/>
          </Route>

        {
          // <Route exact path='/remessa-corp' element={<PrivateRoute/>}>
          //     <Route exact path='/remessa-corp' element={<Fenix/>}/>
          // </Route>
          // <Route exact path='/remessa-corp-rp' element={<PrivateRoute/>}>
          //     <Route exact path='/remessa-corp-rp' element={<Ruplast/>}/>
          // </Route>
          // <Route exact path='/remessa-corp-bb' element={<PrivateRoute/>}>
          //     <Route exact path='/remessa-corp-bb' element={<BrasilBitcoin/>}/>
          // </Route>
          // <Route exact path='/remessa-corp-rx' element={<PrivateRoute/>}>
          //     <Route exact path='/remessa-corp-rx' element={<RebelX/>}/>
          // </Route>
        }

          <Route exact path='/remessa-corp-bb' element={<PrivateRoute/>}>
            <Route exact path='/remessa-corp-bb' element={<CorpApiBrasilBitcoinHome/>}/>
          </Route>
          <Route exact path='/remessa-corp-bb-operations' element={<PrivateRoute/>}>
            <Route exact path='/remessa-corp-bb-operations' element={<CorpApiBrasilBitcoinOperations/>}/>
          </Route>
          <Route exact path='/remessa-corp-bb-clients' element={<PrivateRoute/>}>
            <Route exact path='/remessa-corp-bb-clients' element={<CorpApiBrasilBitcoinClients/>}/>
          </Route>
          <Route exact path='/remessa-corp-bb-remessa/:remessaId' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-bb-remessa/:remessaId' element={<CorpApiBrasilBitcoinRemessa/>}/>
          </Route>

          <Route exact path='/remessa-corp' element={<PrivateRoute/>}>
            <Route exact path='/remessa-corp' element={<CorpApiFenixHome/>}/>
          </Route>
          <Route exact path='/remessa-corp-operations' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-operations' element={<CorpApiFenixOperations/>}/>
          </Route>
          <Route exact path='/remessa-corp-clients' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-clients' element={<CorpApiFenixClients/>}/>
          </Route>
          <Route exact path='/remessa-corp-remessa/:remessaId' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-remessa/:remessaId' element={<CorpApiFenixRemessa/>}/>
          </Route>

          <Route exact path='/remessa-corp-rx' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rx' element={<CorpApiRebelXHome/>}/>
          </Route>
          <Route exact path='/remessa-corp-rx-operations' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rx-operations' element={<CorpApiRebelXOperations/>}/>
          </Route>
          <Route exact path='/remessa-corp-rx-clients' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rx-clients' element={<CorpApiRebelXClients/>}/>
          </Route>
          <Route exact path='/remessa-corp-rx-remessa/:remessaId' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rx-remessa/:remessaId' element={<CorpApiRebelXRemessa/>}/>
          </Route>

          <Route exact path='/remessa-corp-rp' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rp' element={<CorpApiRuplastHome/>}/>
          </Route>
          <Route exact path='/remessa-corp-rp-operations' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rp-operations' element={<CorpApiRuplastOperations/>}/>
          </Route>
          <Route exact path='/remessa-corp-rp-clients' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rp-clients' element={<CorpApiRuplastClients/>}/>
          </Route>
          <Route exact path='/remessa-corp-rp-remessa/:remessaId' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-rp-remessa/:remessaId' element={<CorpApiRuplastRemessa/>}/>
          </Route>

          <Route exact path='/remessa-corp-sb' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-sb' element={<CorpApiSandboxHome/>}/>
          </Route>
          <Route exact path='/remessa-corp-sb-operations' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-sb-operations' element={<CorpApiSandboxClients/>}/>
          </Route>
          <Route exact path='/remessa-corp-sb-clients' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-sb-clients' element={<CorpApiSandboxOperations/>}/>
          </Route>
          <Route exact path='/remessa-corp-sb-remessa/:remessaId' element={<PrivateRoute/>}>
              <Route exact path='/remessa-corp-sb-remessa/:remessaId' element={<CorpApiSandboxRemessa/>}/>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
    );
  }
}

export default AppRoutes;
