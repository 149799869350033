import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Routes from './routes';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import './styles/globalStyles.scss';
import './styles/pages/LoginPage.scss';
import './styles/pages/RecoveryPasswordPage.scss';
import './styles/pages/RegisterDataPage.scss';
import './styles/pages/RegisterPage.scss';
import './styles/pages/UserPage.scss';
import './styles/pages/BankDataPage.scss';
import './styles/pages/HigherWalletPage.scss';
import './styles/pages/RemittancesAndDepositsPage.scss';
import './styles/pages/UsersAndPermissionsPage.scss';
import './styles/pages/RemessaCorpPage.scss';
import './styles/pages/RemessaCorpTransactionPage.scss';

import './styles/components/CoinQuote.scss';
import './styles/components/CoinQuotesBar.scss';
import './styles/components/Footer.scss';
import './styles/components/HigherWallet.scss';
import './styles/components/RemessaQuote.scss';
import './styles/components/RemessaQuoteComplete.scss';
import './styles/components/SideMenu.scss';
import './styles/components/RegisterBankAccount.scss';
import './styles/components/RegisterRecipients.scss';
import './styles/components/BankAccount.scss';
import './styles/components/LastTransactions.scss';
import './styles/components/Balance.scss';
import './styles/components/RegisterUser.scss';
import './styles/components/User.scss';
import './styles/components/ToggleButton.scss';
import './styles/components/RecipientsList.scss';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}

if (window.location.protocol === 'http:' && document.URL.substring(0,16) !== 'http://localhost' && document.URL.substring(0,16) !== 'http://20.14.92.' ) {
    window.location.href =
        window.location.href.replace(
                   'http:', 'https:');
}

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Routes />
  </AlertProvider>
)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

reportWebVitals();
