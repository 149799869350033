import SetupBackEnd from '../config/config.js';
import axios from 'axios';

function diffHours(d1, d2) {
    d1 = new Date(d1).getTime();
    d2 = d2 || new Date().getTime();
    var df = Math.abs(d1 - d2);
    var td = {
        d: Math.round(df / (24 * 60 * 60 * 1000)),
        h: Math.round(df / (60 * 60 * 1000)),
    };

    if(td.d > 0)
        return true;
    else if(td.h > 0)
        return true;
    return false;
}

function authCorp() {
    var data = SetupBackEnd.get('/remessa-corp-auth-rt');

    data.then(function(res){
        localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
        localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
    });
    return data;
}

function auth() {
    var diff  = diffHours(new Date(), new Date(localStorage.getItem('dateTokenRemessaCorpRuplast')));


    if(diff && localStorage.getItem('tokenRemessaCorpRuplast') !== '' && localStorage.getItem('tokenRemessaCorpRuplast') !== null && localStorage.getItem('tokenRemessaCorpRuplast') !== undefined){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({data: {token: localStorage.getItem('tokenRemessaCorpRuplast')}});
            }, 1);
        });
    }

    var data = SetupBackEnd.get('/remessa-corp-auth-rt');

    data.then(function(res){
        localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
        localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
    });
    return data;
}

function createQuote(data) {
    var req = SetupBackEnd.post('/remessa-corp-quote-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-quote-rt', data);
        });
    });
}

function acceptQuote(data) {
    var req = SetupBackEnd.post('/remessa-corp-quote-accept-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-quote-accept-rt', data);
        });
    });
}

function createTransaction(data) {
    var req = SetupBackEnd.post('/remessa-corp-create-transaction-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-create-transaction-rt', data);
        });
    });
}

function returnTransactions(data) {
    var req = SetupBackEnd.post('/remessa-corp-return-transactions-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-return-transactions-rt', data);
        });
    });
}

function returnQuotes(data) {
    var req = SetupBackEnd.post('/remessa-corp-return-quotes', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-return-quotes-rt', data);
        });
    });
}

function returnOldQuotes(data) {
    var req = SetupBackEnd.post('/remessa-corp-return-old-quotes-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-return-old-quotes-rt', data);
        });
    });
}

function getTransactionById(data) {
    var req = SetupBackEnd.post('/remessa-corp-return-transaction-rt', data);

    return req.then(function(res){
        return req;
    }).catch(function(){
        SetupBackEnd.get('/remessa-corp-auth-rt').then(function(res){
            localStorage.setItem('dateTokenRemessaCorpRuplast', new Date());
            localStorage.setItem('tokenRemessaCorpRuplast', res.data.res.access_token);
            return SetupBackEnd.post('/remessa-corp-return-transaction-rt', data);
        });
    });
}

function sendMail(data) {
  return axios.post('https://higher-solucoes-backend.herokuapp.com/remessa-corp-send-mail-rt', data, {
    maxContentLength: 100000000,
    maxBodyLength: 1000000000
  });
}

function getMails() {
  return SetupBackEnd.get('/remessa-corp-get-mails-rt');
}

const RemessaCorp = {
    authCorp,
    auth,
    createQuote,
    acceptQuote,
    createTransaction,
    returnTransactions,
    returnQuotes,
    returnOldQuotes,
    sendMail,
    getMails,
    getTransactionById
}

export default RemessaCorp;
