import SetupBackEnd from './config/config.js';

function login(data) {
    return SetupBackEnd.post('/login', data);
}

function googleLogin(data) {
    return SetupBackEnd.post('/google-login', data);
}

function register(data) {
    return SetupBackEnd.post('/register', data);
}

function getUser(data) {
    return SetupBackEnd.post('/auth-user', data);
}

function partnerLogin(data) {
    return SetupBackEnd.post('/partner-login', data);
}

function partnerRegister(data) {
    return SetupBackEnd.post('/partner-register', data);
}

function verifyPartner(data) {
    return SetupBackEnd.post('/verify-partner', data);
}

function corpRegister(data) {
    return SetupBackEnd.post('/corp-register', data);
}

function verifyCorp(data) {
    return SetupBackEnd.post('/verify-corp', data);
}

function recoveryPassword(data) {
    return SetupBackEnd.post('/recovery-password', data);
}

function returnUserDataByRecoveryToken(data) {
    return SetupBackEnd.post('/user-data-by-recovery-token', data);
}

function updatePassword(data) {
    return SetupBackEnd.post('/update-password', data);
}

function getRegister(data) {
    return SetupBackEnd.post('/return-register', data);
}

function sendRegister(data) {
    return SetupBackEnd.post('/complete-registration', data);
}

function updateRegister(data) {
    return SetupBackEnd.post('/update-register', data);
}

function verifyDataByDoc(data) {
    return SetupBackEnd.post('/verify-data-by-doc', data);
}

function uploadProfileImage(data) {
    return SetupBackEnd.post('/upload-profile-image', data);
}

const Services = {
    login,
    register,
    getUser,
    partnerLogin,
    partnerRegister,
    verifyPartner,
    verifyCorp,
    corpRegister,
    recoveryPassword,
    returnUserDataByRecoveryToken,
    updatePassword,
    getRegister,
    sendRegister,
    verifyDataByDoc,
    googleLogin,
    updateRegister,
    uploadProfileImage,
}

export default Services;
