import axios from 'axios';

const SetupBackEnd = axios.create({
    // baseURL: 'http://localhost:3333'                              // LOCAL export PORT=80 &&
    // baseURL: 'https://homolog.highersolucoes.com:3333'      // HOMOLOG
    // baseURL: 'http://20.14.92.9:3333'      // HOMOLOG
    baseURL: 'https://higher-solucoes-backend.herokuapp.com'         // PRODUCTION

    //  sudo pm2 start npm --name frontend -- run serve-build
});

export default SetupBackEnd;
